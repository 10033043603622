.filter-wrapper {
    text-align: center;
    margin-top: 40px;

    h3 {
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .filter-btns {

        a {
            color: #fff;
            text-decoration: none;
            display: inline-block;
            border: 1px solid #fff;
            border-radius: 24px;
            padding: 0;
            min-width: 80px;
            line-height: 32px;
            font-size: 12px;
            font-family: "obviously-wide", sans-serif;
            font-weight: 600;
            font-style: normal;
            text-transform: uppercase;
            margin-right: 8px;

            &:last-of-type {
                margin-right: 0;
            }
            
            span {
                position: relative;
                bottom: 2px;
                pointer-events: none;
            }
        }
    }

    &--blue {

        .filter-btns .filter-btn--active {
            background-color: #fff;
            color: var(--blue);
        }
    }

    &--red {

        .filter-btns  .filter-btn--active {
            background-color: #fff;
            color: var(--red);
        }
    }

    &--yellow {

        .filter-btns .filter-btn--active {
            background-color: #fff;
            color: var(--yellow);
        }
    }
}