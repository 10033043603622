.section__activities {
    background-color: var(--red);
    color: #fff;

    .filter-btns {

        a {
            min-width: 150px;
        }
    }
}

.activity-wrapper {
    position: relative;
}

.activity__intiniary {
    padding: 40px 30px 80px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    
    &.fade-out {
        opacity: 0;
        width: 0;
        height: 0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
        pointer-events: none;
    }

    &.fade-in {
        opacity: 1;
        width: calc(100% - 60px);
        height: auto;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
        position: relative;
    }

    .activity {

        &__inner {

        }

        &__day {
            margin-top: 32px;

            h4 {
                text-transform: uppercase;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 18px;
            }
        }

        &__item {

            &--empty {

                .Collapsible__trigger {
                    cursor: default;

                    .collapse__trigger {

                        img, svg {
                            display: none;
                        }
                    }
                }
            }

            .collapse__trigger {
                display: flex;
                align-items: center;
                
                span {
                    line-height: 1.25;
                    padding-right: 30px;

                    &.collapse__time {
                        padding-right: 0;
                        min-width: 60px;
                    }
                }
            }

            .Collapsible__trigger .collapse__trigger svg {
                top: 50%;
                translate: rotate(-180deg) translateY(-50%);
            }

            .Collapsible__trigger.is-open .collapse__trigger svg {
                translate: rotate(0) translateY(-50%);
            }

            .Collapsible .Collapsible__contentInner p {

                a {
                    color: var(--red);
                    display: block;
                    margin-top: 0px;
                }

                > div {
                    margin-top: 21px;
                }
            }

            .item {

            }

            &:last-of-type {

                .item__desc {
                    margin-bottom: 0;
                }
            }
        }
    }
}