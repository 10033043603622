.hero {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-size: 100%;
    background-position: 0 0;
    padding-bottom: 250px;
    background-repeat: no-repeat;

    .hero__inner {

    }

    .hero__logo {
        margin-top: 160px;
        margin-bottom: 140px;
        padding: 0 10px 0 30px;

        video, img, svg {
            width: 100%;
        }
    }

    .button-group {

        .btn {
            margin-bottom: 12px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    h1 {
        color: #fff;
        text-align: center;
        margin-bottom: 120px;
    }
}

@media (min-width: 800px) {

    .hero {
        padding-bottom: 90px !important;
    }

    .hero .hero__logo {
        margin-top: 210px !important;
        margin-bottom: 180px !important;
    }
}

@media (min-height: 660px) {

    .hero .hero__logo {
        margin-top: 160px;
        margin-bottom: 90px;
    }

    .hero {
        padding-bottom: 230px;
    }
}