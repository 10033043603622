:root {
  --blue: #004FCC;
  --red: #DB0000;
  --yellow: #F2AA0C;
}

@import url("https://use.typekit.net/qnj3cej.css");

body {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  font-family: "obviously", sans-serif;
  font-weight: 400;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body, html {
  max-width: 100vw;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "obviously-wide", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.section {
  padding: 40px 11px;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  .top-divider {
    position: absolute;
    top: -31px;
    left: 0;
    width: 100%;
  }

  .bottom-divider {
    position: absolute;
    bottom: -35px;
    left: 0;
    width: 100%;
  }

  .top-divider, .bottom-divider {

    &--blue {

    }
    
    &--red path {
      fill: var(--red);
    }

    &--yellow path {
      fill: var(--yellow);
    }
  }

  .header-wrapper {
    padding: 0 20px;

    p {
      margin-top: 30px;
      text-align: center;
      font-size: 12px;
      font-family: "obviously-wide", sans-serif;
      font-weight: 600;
      font-style: normal;
      line-height: 19px;
    }
  }
}

.page-wrapper > img {
  display: none;
}

.text {

  &--red {
    color: var(--red);
  }

  &--blue {
    color: var(--blue);
  }

  &--yellow {
    color: var(--yellow);
  }
}

.bordered {
  position: absolute;
  top: 0;
  width: 36px;
  height: 100%;
  pointer-events: none;
  
  &--left {
    right: -11px;
  }

  &--right {
    left: -11px;
  }

  &--blue path {

  }

  &--red path {
    fill: var(--red);
  }
  
  &--yellow path {
    fill: var(--yellow);
  }
}

.chevron {

  &--blue path {
    fill: var(--blue);
  }

  &--red path {
    fill: var(--red);
  }

  &--yellow path {
    fill: var(--yellow);
  }
}

.Collapsible {
  //border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 18px 0;

  &:last-of-type {
    border-top: none;
  }

  .Collapsible__contentOuter {

  }

  .Collapsible__contentInner {
    padding: 0 17px;

    .collapse__content {
      margin: 28px 0 20px;
    }

    p {
      font-size: 12px;
      line-height: 19px;
      margin-bottom: 14px;
      margin-top: 0;

      a {
        display: block;
        text-decoration: underline;
        color: #000;
      }

      &:last-of-type {
        margin-top: 0;
      }

      br {
        display: block;
      }
    }
  }

  &__trigger {

    &.is-open {

      .collapse__trigger svg {
        transform: rotate(0deg) translateY(-50%);
      }
    }

    .collapse {

      &__trigger {
        position: relative;
        bottom: 2px;

        svg {
          position: absolute;
          right: 0;
          top: 50%;
          transform: rotate(-180deg) translateY(-50%);
          transition: .4s all ease;
        }
      }

      &__time {
        font-size: 14px;
        font-family: "obviously-wide", sans-serif;
        font-weight: 600;
        font-style: normal;
        line-height: 1;
        min-width: 52px;
        margin-right: 20px;
      }

      &__label {
        font-size: 14px;
        //line-height: 1;
        font-weight: 500;
        padding-right: 30px;
        display: block;
      }
    }
  }
}

.thanks {
  width: calc(100% - 40px);
  padding: 40px 20px;
  text-align: center;
  
  p {
    font-size: 18px;
    line-height: 1.3;
    color: var(--red);
    opacity: 0.75;
  }

  svg {
    opacity: 0.5;
    margin: 0 auto;
  }
}

.inline-block {
  display: inline-block;
}

@media (min-width: 500px) {

  .page-wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow: hidden;

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 570px;
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      z-index: 9;
      display: block;
      pointer-events: none;
    }

    &__inner {
      max-width: 375px;
      overflow-y: scroll;
      position: absolute;
      top: 0;
      max-height: 780px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@media (min-width: 800px) {

  .section .bottom-divider {
    bottom: -27px;
  }

  .bordered--left {
    right: -3px;
  }

  .bordered--right {
    left: -3px;
  }
}