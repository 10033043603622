.btn {
    border: 1px solid #fff;
    border-radius: 25px;
    padding: 11px 0;
    text-decoration: none;
    color: #000;
    background-color: #fff;
    display: block;
    width: 187px;
    margin: 0 auto 20px;
    line-height: 48px;
    padding: 0;

    img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        margin-left: 16px;
        position: relative;
        top: 4px;
    }

    span {
        position: relative;
        bottom: 3px;
        font-size: 12px;
        font-family: "obviously-wide", sans-serif;
        font-weight: 600;
        font-style: normal;
        text-transform: uppercase;
    }

    &--blue {
        //background-color: var(--blue);
        color: var(--blue);
    }

    &--red {
        //background-color: var(--red);
        color: var(--red);
    }

    &--yellow {
        //background-color: var(--yellow);
        color: var(--yellow);
    }
}