.slanted-heading {
    background-color: #fff;
    font-size: 28px;
    line-height: 1;
    padding: 13px;
    line-height: 1;
    margin: 0;
    text-transform: uppercase;
    display: block;
    text-align: center;
    transform: rotate(-2.5deg);
    position: relative;
    left: 4px;

    span {
        position: relative;
        bottom: 3px;
    }
    
    &.text--blue {
        color: var(--blue);
    }

    &.text--red {
        color: var(--red);
    }

    &.text--yellow {
        color: var(--yellow);
    }
}