.section__schedule {
    background-color: var(--blue);
    color: #fff;
    position: relative;
}

.intiniary-wrapper {
    position: relative;
}

.schedule__intiniary {
    padding: 40px 40px 80px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 80px);
    
    &.fade-out {
        opacity: 0;
        width: 0;
        height: 0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
        pointer-events: none;
    }

    &.fade-in {
        opacity: 1;
        width: calc(100% - 80px);
        height: auto;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
        position: relative;
    }

    .intiniary {

        &__inner {

        }

        &__day {
            margin-top: 32px;

            h4 {
                color: var(--blue);
                text-transform: uppercase;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 18px;
            }
        }

        &__item {
            display: flex;

            .item {

                &__time {
                    color: var(--blue);
                    font-family: "obviously-wide", sans-serif;
                    font-weight: 600;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 1;
                    flex: 0 0 70px;
                    border-right: 1px solid #000;
                }

                &__desc {
                    flex: 0 0 calc(100% - 70px);
                    padding-left: 14px;
                    position: relative;
                    bottom: 4px;
                    margin-bottom: 8px;
                }
            }

            &:last-of-type {

                .item__desc {
                    margin-bottom: 0;
                }
            }
        }
    }
}