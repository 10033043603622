.section__info {
    background-color: var(--yellow);
    color: #fff;
}

.info-wrapper {
    position: relative;
}

.faq-wrapper {
    position: relative;
}

.info__intiniary {
    padding: 40px 30px 80px;

    .Collapsible .Collapsible__contentInner p a {
        color: var(--yellow);
    }
    
    &.fade-out {
        opacity: 0;
        width: 0;
        height: 0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
        pointer-events: none;
    }

    &.fade-in {
        opacity: 1;
        width: 100%;
        height: auto;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
        position: relative;
    }

    .info {

        &__inner {

        }

        &__day {
            margin-top: 80px;

            &:first-of-type {
                margin-top: 32px;
            }

            h4 {
                text-transform: uppercase;
                font-size: 18px;
                line-height: 27px;
                margin-bottom: 18px;
            }
        }

        &__item {

            .item {

            }

            &:last-of-type {

                .item__desc {
                    margin-bottom: 0;
                }
            }
        }
    }
}